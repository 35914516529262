/**
 * @author johan.magnusson@svenskaspel.se (Johan Magnusson)
 */
import '../../stylesheets/card-group.less';
import PropTypes from 'prop-types';

const CardGroup = (props) => {
  const classNames = ['card-group'];

  if (props.className) {
    classNames.push(props.className);
  }

  return (
    <div className={classNames.join(' ')}>{props.children}</div>
  );
};

CardGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default CardGroup;
